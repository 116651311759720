import locale77427e78 from '../../lang/en.js'
import locale77c06943 from '../../lang/nl.js'
import locale7744c453 from '../../lang/es.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentFallbackWarn":true},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en.js"},{"code":"nl","iso":"nl-NL","file":"nl.js"},{"code":"es","iso":"es-ES","file":"es.js"}],
  defaultLocale: "nl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en.js"},{"code":"nl","iso":"nl-NL","file":"nl.js"},{"code":"es","iso":"es-ES","file":"es.js"}],
  localeCodes: ["en","nl","es"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "e",
  19: "n",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: ",",
  25: "\"",
  26: "n",
  27: "l",
  28: ".",
  29: "j",
  30: "s",
  31: "\"",
  32: ":",
  33: "\"",
  34: ".",
  35: ".",
  36: "/",
  37: "l",
  38: "a",
  39: "n",
  40: "g",
  41: "/",
  42: "n",
  43: "l",
  44: ".",
  45: "j",
  46: "s",
  47: "\"",
  48: ",",
  49: "\"",
  50: "e",
  51: "s",
  52: ".",
  53: "j",
  54: "s",
  55: "\"",
  56: ":",
  57: "\"",
  58: ".",
  59: ".",
  60: "/",
  61: "l",
  62: "a",
  63: "n",
  64: "g",
  65: "/",
  66: "e",
  67: "s",
  68: ".",
  69: "j",
  70: "s",
  71: "\"",
  72: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'nl.js': () => Promise.resolve(locale77c06943),
  'es.js': () => Promise.resolve(locale7744c453),
}
